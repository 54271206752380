<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-12 mb-0">
                <div class="card">
                    <form @submit.prevent="CreateRecipe">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 mb-2">
                                <div class="float-sm-left">
                                    <router-link
                                            to="/recipe"
                                            class="btn btn-info"
                                    ><i class="mdi mdi-arrow-left-circle-outline mr-1"></i> Back
                                    </router-link >
                                </div>
                            </div>
                        </div>

                        <h5 class="mb-3 text-uppercase bg-light text-primary rounded-lg p-2">
                            <i class="mdi mdi-account-circle mr-1"></i> Recipe information
                        </h5>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <div class="form-group mb-3">
                                            <label for="name"> Name</label>
                                            <input
                                                    class="form-control"
                                                    v-model="recipe.name"
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    placeholder="Enter recipe name"
                                                    v-validate="'required'"
                                                    :class="{'is-invalid': errors.has('name') }"
                                            />
                                            <span v-show="errors.has('name')" class="help text-danger">{{ errors.first('name') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group mb-3">
                                            <label for="name"> Calories</label>
                                            <input
                                                    class="form-control"
                                                    v-model="recipe.calories"
                                                    type="number"
                                                    id="calories"
                                                    name="name"
                                                    placeholder="Enter calories"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mb-3">
                                            <label for="name"> Active Time InMinute(s)</label>
                                            <input
                                                    class="form-control"
                                                    v-model="recipe.activeTimeInMins"
                                                    type="number"
                                                    id="activeTimeInMins"
                                                    name="name"
                                                    placeholder="Active Time In Minutes"
                                            />
<!--                                            <span v-show="errors.has('activeTimeInMins')" class="help text-danger">{{ errors.first('activeTimeInMins') }}</span>-->
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mb-3">
                                            <label for="name"> Serving Time In Minute(s)</label>
                                            <input
                                                    class="form-control"
                                                    v-model="recipe.servingTimeInMins"
                                                    type="number"
                                                    id="servingTimeInMins"
                                                    name="name"
                                                    placeholder="Serving Time In Minutes"

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col lg-12">
                                  <div class="row">
                                      <div class="col-lg-6">
                                          <div class="row">
                                              <div class="col-lg-6">
                                                  <div class="form-group mb-3">
                                                      <label>Category</label>
                                                      <select class="form-control" v-model="recipe.categoryId" name="categoryId" id="categoryId" v-validate="'required'" >
                                                          <option v-for="cat in categoryList" :value="cat.id" :key="cat.id">{{cat.categoryName}}</option>
                                                      </select>
                                                      <span v-show="errors.has('categoryId')" class="help text-danger">{{ errors.first('categoryId') }}</span>
                                                  </div>
                                              </div>
                                              <div class="col-lg-6">
                                                  <div class="form-group mb-3">
                                                      <label for="name"> Preparation Time In Minute(s)</label>
                                                      <input
                                                              class="form-control"
                                                              v-model="recipe.preparationTimeInMins"
                                                              type="number"
                                                              id="preparationTimeInMins"
                                                              name="name"
                                                              placeholder="Preparation Time In Minutes"

                                                      />
                                                  </div>
                                              </div>
                                          </div>

                                      </div>
                                      <div class="col-lg-6">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group mb-3">
                                                        <label for="name"> Number of Servings</label>
                                                        <input
                                                                class="form-control"
                                                                v-model="recipe.noOfServings"
                                                                type="number"
                                                                id="noOfServings"
                                                                name="name"
                                                                placeholder="Number of serving"

                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group mb-3">
                                                        <label for="name"> Cooking Time in Minute(s)</label>
                                                        <input
                                                                class="form-control"
                                                                v-model="recipe.cookingTimeInMins"
                                                                type="number"
                                                                id="cookingTimeInMins"
                                                                name="name"
                                                                placeholder="Cooking Time In Minutes"

                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                      </div>
                                  </div>
                            </div>
                             <div class="col-lg-12">
                                 <div class="form-group mb-3">
                                     <label for="name"> Description</label>
                                      <textarea class="form-control"  v-model="recipe.description"></textarea>
                                 </div>
                             </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mb-3">
                                            <label for="name">Instructions</label>
                                            <div class="form-group mb-1">
                                                     <textarea class="form-control"  v-model="recipe.instructions"

                                                     ></textarea>
                                            </div>
                                            <div class="form-group">
                                                <button class="btn btn-primary" type="button" @click="AddInstructionToList"><i class="fa fa-plus-circle"></i> Add </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label  v-if="listofInstructions.length > 0" class="text-dark"> List of Instructions</label>
                                        <div class="form-group" v-if="listofInstructions.length > 0">
                                            <div class="card mb-2" v-for="(data,i) in listofInstructions" :key="i">
                                                <div class="p-2">
                                                    <div class="row align-items-center">
                                                        <div class="col-auto">
                                                            <div class="avatar-sm">
                                                                <span class="p-1 text-primary rounded">{{i+1}}</span >
                                                            </div>
                                                        </div>
                                                        <div class="col pl-0">

                                                            <p class="font-12">{{data}}</p>
                                                        </div>
                                                        <div class="col-auto">
                                                            <!-- Button -->
                                                            <a href="javascript:void(0);"
                                                               class="btn btn-link font-16 text-muted" @click="DeleteInstruction(i)"
                                                            >
                                                                <i class="text-danger fa fa-trash"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <hr />

                            <div class="col-lg-12">
                                <div class="form-group mb-3">
                                    <label for="name">Ingredients</label>
                                    <ckeditor v-model="recipe.ingredients" :editor="editor"></ckeditor>
                                </div>

                            </div>

                        </div>

                        <h5 class="mb-3 text-uppercase bg-light text-primary rounded-lg p-2">
                            <i class="mdi mdi-account-circle mr-1"></i> Recipe images
                        </h5>

                        <div class="row mb-3">
                            <div class="col-lg-12 mb-5">
                                <div>
                                    <lottie-loader :loading="showLoaderImage" />
                                </div>
                                <div class="table-responsive mb-0">
                                    <table class="table table-borderless mb-0">
                                        <thead class="thead-light">
                                        <tr>
                                            <th>Index</th>
                                            <th>Image Type</th>
                                            <th>Image</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(image,index) in imageArrayList" :key="index">
                                            <td>{{index + 1}}</td>
                                            <td><span class="p-1 badge"> {{index === 0 ? 'Main image' : ' Additional Image'}}</span></td>
                                            <td><img :src="image.imageUrl" width="50" height="50" /></td>
                                            <td><button type="button" v-if="image" @click="DeleteExistingImage(image.fileId)" class="btn btn-danger mb-1"> Delete </button></td>

                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="col-lg-12 mb-3">
                                <div class="row">
                                    <div class="col-lg-7">
                                        <VueFileAgent
                                                :deletable="true"
                                                ref="vueFileAgent"
                                                v-model="selectedFiles"
                                                @select="filesSelected($event)"
                                                @beforedelete="onBeforeDelete($event)"
                                                @delete="fileDeleted($event)"
                                                :maxFiles="1"
                                                :multiple="true"
                                                :errorText="{
                                          type: 'Invalid file type. Only images or zip Allowed',
                                          size: 'Files should not exceed 10MB in size',
                                        }"
                                        ></VueFileAgent>
                                    </div>
                                    <div class="col-lg-5">
                                        <button class="btn btn-primary" @click="AddImageToList" type="button" v-if="selectedFiles"> Add Image to list</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="mb-3 text-uppercase bg-light text-primary rounded-lg p-2">
                            <i class="mdi mdi-account-circle mr-1"></i> Add product to recipe
                        </h5>

                         <div class="row">
                             <div class="col-lg-12">
                                 <div class="row">
                                     <div class="col-lg-5">
                                           <div class="row">
                                               <div class="col-lg-7">
                                                   <div class="form-group mb-3">
                                                       <label for="name"> Search Product</label>
                                                       <input
                                                               class="form-control"
                                                               v-model="productName"
                                                               type="text"
                                                               id="productName"
                                                               name="productName"
                                                               placeholder="Search product by name"
                                                               @input="debouncedSearchForProductName"
                                                       />
                                                        <ul class="list-group">
                                                            <li class="list-group-item" v-for="p in searchProducts" :key="p.id"><span style="cursor: pointer;" @click="getSelectedProduct(p)">{{p.productName}}</span></li>
                                                        </ul>
                                                   </div>
                                               </div>
                                               <div class="col-lg-5 " style="margin-top : 28px;">

                                                   <button class="btn btn-info" type="button" @click="addProductToProductList"> <i class="fa fa-plus-circle"></i> Add to product list</button>
                                               </div>
                                           </div>
                                     </div>
                                     <div class="col-lg-7">
                                         <div class="table-responsive mb-0">
                                             <table class="table table-borderless mb-0">
                                                 <thead class="thead-light">
                                                 <tr>
                                                     <th>Product Id</th>
                                                     <th>Product Name</th>
                                                     <th>Action</th>
                                                 </tr>
                                                 </thead>
                                                 <tbody>
                                                 <tr v-for="p in listOfSelectedProduct" :key="p.id">
                                                     <td>{{p.id}}</td>
                                                     <td>{{p.name}}</td>
                                                     <td><span @click="DeleteProductFromList(p.id)"  class="fa fa-trash text-danger" style="cursor: pointer"></span> </td>
                                                 </tr>
                                                 </tbody>
                                             </table>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                        <div class="col-lg-12">
                            <div class="form-group float-right">
                                <button class="btn btn-primary" type="submit">Create Recipe</button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>

    import Layout from "../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import SweetAlertWrapper from "../../../../sweetAlert";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    import LottieLoader from "../../../components/LottieLoader";
    import CKEditor from "@ckeditor/ckeditor5-vue";
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
    import SweetAlertConfig from "../../../../SwalConfig";
    import Vue from "vue";
    export default {
        name: "AddRecipe",
        components : {
            Layout,
            PageHeader,
            LottieLoader,
            ckeditor: CKEditor.component,
        },
        data() {
            return {
                showLoader : false,
                showLoaderImage : false,
                selectedFiles :'',
                editor: ClassicEditor,
                title: "Create Recipe",
                imageArrayList:[],
                productName :'',
                 listOfSelectedProduct:[],
                 listOfSelectedProductIds : [],
                 searchProducts : [],
                 selectedProduct:{},
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "recipe",
                        active: true,
                    },
                ],

                listOfImages : [],
                selectedRecipe : {},
                listofInstructions : [],
                categoryList : [],
                recipe : {
                    name : '',
                    activeTimeInMins :0,
                    servingTimeInMins :0,
                    calories:0,
                    preparationTimeInMins:0,
                    noOfServings : 0,
                    cookingTimeInMins : 0,
                    description : '',
                    ingredients : '',
                    instructions : ''
                }
            }
        },
        methods : {
            addProductToProductList(){
                 console.log(this.selectedProduct)
                if(this.productName.length > 0) {

                    let checkIfItemExistInList = this.listOfSelectedProduct.filter(x=>x.id === this.selectedProduct.id)
                    if(checkIfItemExistInList.length > 0) {
                        this.$bvToast.toast(`${this.selectedProduct.productName} already exist in the list` , ToastConfigAlert)
                        this.productName = ''
                        return;
                    }

                    this.listOfSelectedProduct.push({
                        id : this.selectedProduct.id,
                        name : this.selectedProduct.productName
                    })
                    this.selectedProduct = {}
                     this.productName = ''
                }
            },
            getSelectedProduct(product) {
                this.selectedProduct = product;
                this.productName = product.productName;
                this.searchProducts = []
            },
            DeleteProductFromList(productId) {
               this.listOfSelectedProduct = this.listOfSelectedProduct.filter(x => x.id !== productId)
            },
            debouncedSearchForProductName(){
                clearTimeout(this.debounceTimeout);

                // Set a new timeout to execute the search after 500 milliseconds
                this.debounceTimeout = setTimeout(() => {
                    this.GetProductDetailsByProductName(this.productName);
                }, 500);
            },
            GetProductDetailsByProductName(name) {

                BaseUrl.AxiosBearer(`store/search?searchModule=PRODUCTS&classification=GROCERIES&page=1&pageSize=20&searchText=${name}`).then((resp) => {
                    if(resp) {
                        this.searchProducts = []
                        this.searchProducts = resp.data.payload

                        console.log("product name : ", this.searchProducts)
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })
            },
            AddInstructionToList(){


                 if(this.recipe.instructions.length > 0){
                     this.listofInstructions.push(this.recipe.instructions)
                     this.recipe.instructions =''
                 }

            },
            DeleteInstruction(index) {
                this.listofInstructions.splice(index, 1)
            },
            DeleteExistingImage(fileId){
                let newArray =  this.imageArrayList.filter(a => a.fileId !== fileId)
                this.imageArrayList = [...newArray]
            },
            filesSelected: function (fileRecordsNewlySelected) {
                console.log(" selected file : ", fileRecordsNewlySelected)
                let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
                this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            },
            deleteUploadedFile: function (fileRecord) {
                // Using the default uploader. You may use another uploader instead.
                this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
            },
            onBeforeDelete: function (fileRecord) {
                console.log(" on before delete : ", fileRecord)
                this.fileRecordsForUpload = []
                // this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
                this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
                this.selectedFiles = ''

            },

            fileDeleted: function (fileRecord) {
                let i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    this.fileRecordsForUpload.splice(i, 1);
                } else {
                    this.deleteUploadedFile(fileRecord);
                }

                this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
            },
            getCatoriesByCatType() {
                BaseUrl.AxiosBearer(`/store/categories/get-all-categories?classification=RECIPE`).then((resp) => {

                    if(resp) {
                        this.categoryList = []
                        this.categoryList = resp.data.payload
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })
            },
            AddImageToList() {

                const imageData = {
                    file : this.selectedFiles.file,
                    name : this.recipe.name
                }
                const formData = new FormData()
                formData.append("name", imageData.name)
                formData.append("file", imageData.file)
                this.showLoaderImage = true
                BaseUrl.AxiosBearer.post("/store/uploads/upload-single-file" , formData, {

                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },

                }).then((res) => {
                    this.showLoaderImage = false
                    if (!res.data.status) {
                        this.$bvToast.toast(res.data?.message, ToastConfigAlert)
                        return;
                    } else {

                        this.imageArrayList.push({
                            imageUrl : res.data?.payload?.fileUrl,
                            fileId : res.data?.payload?.fileId
                        })

                        this.onBeforeDelete(this.selectedFiles)
                        this.imageArrayList = [...this.imageArrayList]

                    }
                }).catch((error) => {
                    console.log("error : ", error)
                    this.showLoaderImage = false
                    this.$bvToast.toast(error?.message , ToastConfigAlert)
                    return;
                })

            },
            clearControls(){
                this.recipe = {
                    name : '',
                    activeTimeInMins :0,
                    servingTimeInMins :0,
                    calories:0,
                    ingredients : '',
                    instructions : ''
                }
                this.imageArrayList =[];
                this.productName ='';
                this.listofInstructions = [];
                this.listOfSelectedProduct=[];
                this.listOfSelectedProductIds = [];
                this.$validator.reset();
            },
            CreateRecipe(){
                this.$validator.validateAll().then((result) => {

                    if (result) {

                        // if(this.imageArrayList.length < 1) {
                        //     this.$bvToast.toast("Kindly add at least one image to the list" , ToastConfigAlert)
                        //     return;
                        // }

                        this.sweetAlert.showDialogYesOrNo(Object.assign(SweetAlertConfig, {
                            title : "Add Recipe ?",
                            confirmButtonText :"Yes Add"
                        }), () => {
                            this.listOfSelectedProductIds = this.listOfSelectedProduct.map(x => x.id)
                             console.log(" selected product ids : ", this.listOfSelectedProductIds)
                            const recipeData = {
                                 //recipe about to be added
                                recipeName : this.recipe.name,
                                activeTimeInMins : parseInt(this.recipe.activeTimeInMins),
                                servingTimeInMins : parseInt(this.recipe.servingTimeInMins),
                                categoryId : this.recipe.categoryId,
                                calories : parseInt(this.recipe.calories),
                                ingredients : this.recipe.ingredients,
                                instructions : this.listofInstructions,
                                preparationTimeInMins : parseInt(this.recipe.preparationTimeInMins),
                                noOfServings : parseInt(this.recipe.noOfServings),
                                cookingTimeInMins : parseInt(this.recipe.cookingTimeInMins),
                                description : this.recipe.description,
                                mainImage: {
                                    imageUrl:  this.imageArrayList[0].imageUrl,// res.data?.payload?.fileUrl,
                                    altText: "An Image",
                                    fileId  : this.imageArrayList[0].fileId,
                                },
                                additionalImages:  this.listOfImagesWIthoutTheFirstImage.map((data) => ({
                                    imageUrl: data.imageUrl,
                                    fileId: data.fileId,
                                    altText: "An image",
                                })),
                               productIds :this.listOfSelectedProductIds
                            }

                            // CREATE RECIPE ENDPOINT
                            console.log("Data being submited : ", recipeData)
                            this.showLoader = true
                            BaseUrl.AxiosBearer.post("/store/recipes/add-recipe", recipeData).then((resp) => {
                                this.showLoader = false
                                if(!resp.data.status) {
                                    this.$bvToast.toast(resp.data.message , ToastConfigAlert)
                                }
                                else {
                                    this.sweetAlert.showMessage("Recipe added!  ",resp.data.message)
                                    this.clearControls()
                                    this.fileDeleted(this.selectedFiles)
                                }

                            }).catch(({ response: error }) => {
                                console.log(" error : ", error)
                                let { message } = error.data;

                                if(typeof message == 'object') {
                                    message = message.join('\n\n')
                                    console.log('entered')
                                }

                                console.log(" error : ", message)
                                this.showLoader = false
                                this.$bvToast.toast(message , ToastConfigAlert)

                            })

                            return;
                        })
                    }
                })

            }
        },
        created() {
            this.sweetAlert = new SweetAlertWrapper()
            this.getCatoriesByCatType()
        },
        computed :{
            listOfImagesWIthoutTheFirstImage() {
                return this.imageArrayList.slice(1);
            }
        }
    }
</script>

<style scoped>

</style>